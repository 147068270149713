import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Alert, Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import { NavHashLink } from "react-router-hash-link";
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, onSnapshot, collection } from 'firebase/firestore';
import { animateScroll as scroll } from 'react-scroll';
import { CiWarning } from "react-icons/ci";

function NavBar() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState(0);
  const [profileUrl, setProfileUrl] = useState("images/Group.svg");
  const [showWarning, setShowWarning] = useState(false);

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const navigate = useNavigate();

  const handleHashLinkClick = (event) => {
    if (window.innerWidth < 960) {
      event.preventDefault();
      navigate("/Pricing")
      scroll.scrollTo(2300);
      setTimeout(handleCloseOffcanvas, 500);
    }
  };
  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDocRef = doc(db, 'userProfiles', currentUser.uid);
        const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            setCredits(docSnapshot.data().credits || 0);
            setProfileUrl(docSnapshot.data().photoUrl || "images/Group.svg");

          }
        });

        return () => unsubscribeSnapshot();
      } else {
        setUser(null);
      }
    });
    const systemDocRef = doc(db, "dashBoard", "system");
    const unsubscribeSystem = onSnapshot(systemDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        if (docSnapshot.data().pennyskips === false && docSnapshot.data().titanskip === false || docSnapshot.data().maintenance === true) {
          setShowWarning(true);
        } else {
          setShowWarning(false);
        }

      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribeSystem();
    };
  }, []);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      localStorage.clear();
      navigate("/SignIn");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <>
      {showWarning && (
        <Alert variant="warning" onClose={() => setShowWarning(false)} dismissible className="alert">
          <Alert.Heading><CiWarning /> There's currently an ongoing service maintenence. Skiptracing will be back soon.</Alert.Heading>

        </Alert>
      )}

      <Navbar expand="lg" className="bg-body-tertiary navbarr mb-3"
        style={{ top: showWarning ? '30px' : '0px' }}
      >
        <Container fluid className="d-flex justify-content-between align-items-baseline">
          <Navbar.Brand>
            <NavLink to="/" className="ImgNav">
              <img
                src="images/logo.svg"
                alt="logo"
                className="logoImg"
              />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle
            className="textttt"
            aria-controls="offcanvasNavbar"
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          />
          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={handleCloseOffcanvas}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            className="justify-content-between"
          >
            <Offcanvas.Header closeButton>

            </Offcanvas.Header>
            <Offcanvas.Body className="textttt">
              <Nav className="navgroup align-items-center w-100 justify-content-between">
                <NavLink to="/" onClick={handleCloseOffcanvas}>
                  Home
                </NavLink>
                <NavLink to="/about-us" onClick={handleCloseOffcanvas}>
                  About Us
                </NavLink>

                <NavHashLink smooth to="/skiptracing" onClick={handleCloseOffcanvas}>
                  Skiptracing
                </NavHashLink>
                <NavLink smooth to="/build-va-team" onClick={handleCloseOffcanvas}>
                  Build Your VA Team
                </NavLink>
                <NavLink smooth to="/Pricing" onClick={handleCloseOffcanvas}>
                  Pricing
                </NavLink>
                <NavLink smooth to="/Blogs" onClick={handleCloseOffcanvas}>
                  Blogs/FAQ
                </NavLink>

                {user ? (
                  <div className="d-flex align-items-center divLeft">
                    <span>{credits} Credits</span>
                    <NavHashLink to="/Pricing#pricingCheck" onClick={handleHashLinkClick}>
                      <button className="signbtn">Buy Credits</button>
                    </NavHashLink>
                    <NavDropdown title={<img className="urlphoto" src={profileUrl} alt="" />} id="basic-nav-dropdown">
                      <Dropdown.Item smooth onClick={handleCloseOffcanvas}>
                        <NavLink to="/Account">Account</NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}><NavLink to="#">Logout</NavLink></Dropdown.Item>
                    </NavDropdown>
                  </div>
                ) : (
                  <NavLink smooth to="/SignIn" onClick={handleCloseOffcanvas}>
                    <button className="signbtn">Sign up/Sign in</button>
                  </NavLink>
                )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
