import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import { Col, Container, Row, Table, Pagination, Modal, Button } from 'react-bootstrap';
import { FiUpload } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, onSnapshot, doc } from 'firebase/firestore';
import { app } from './firebase';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';

const db = getFirestore(app);

function ListHistory() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState('');
    // const [fileType, setFileType] = useState('');
    const [showWarning, setShowWarning] = useState(false);

    const navigate = useNavigate();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };

    useEffect(() => {
        const uid = localStorage.getItem("uid");
        if (!uid) {
            navigate('/SignIn');
            return;
        }
        const filesRef = doc(db, "userProfiles", uid);



        const unsubscribe = onSnapshot(filesRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const sortedFiles = Object.entries(data.files).map(([id, file]) => ({
                    id,
                    ...file
                })).sort((a, b) => {
                    return new Date(b.timestamp) - new Date(a.timestamp); // Sort descending by timestamp
                });

                setTableData(sortedFiles);
            } else {
                setTableData([]);
            }
        });

        const systemDocRef = doc(db, "dashBoard", "system");
        const unsubscribeSystem = onSnapshot(systemDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                if (docSnapshot.data().pennyskips === false && docSnapshot.data().titanskip === false || docSnapshot.data().maintenance === true) {
                    setShowWarning(true);
                  } else {
                    setShowWarning(false);
                  }
            }
        });


        return () => {
            unsubscribe()
            unsubscribeSystem();

        };
    }, [navigate]);

    const handleDownloadClick = (fileId) => {
        setSelectedFileId(fileId);
        setShowModal(true);
    };
    const handleFileTypeSelection = (Type) => {
        const downloadUrl = `https://api.v1.badskip.com/skiptracing/download?fileId=${selectedFileId}&fileType=${Type}`;
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${selectedFileId}.csv`;
        a.click();

        setShowModal(false);
    };
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(tableData.length / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPagination = () => {
        const pageNumbers = [];
        const maxPagesToShow = 8;
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (endPage - startPage < maxPagesToShow - 1) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        return (
            <Pagination>
                <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
                {pageNumbers}
                <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
            </Pagination>
        );
    };
    return (
        <>
            <Helmet>
                <title>BadSkip | Precision Skiptracing Services
                </title>
            </Helmet>
            <section className='listUpload'>
                <Container>
                    <Row className='justify-content-between my-5 px-5'>
                        <Col md={4} sm={4}>
                            <div className='list'>
                                <img src="images/liost.svg" alt="empty" />
                                <h3>List History </h3>
                            </div>
                        </Col>
                        <Col md={3} sm={3} className='uploaddiv'>
                            <Link
                                onClick={(e) => {
                                    if (showWarning) {
                                        e.preventDefault();
                                        Swal.fire({
                                            icon: 'warning',
                                            title: 'System Maintenance',
                                            text: "There's currently an ongoing service maintenance. Skiptracing will be back soon.",
                                            customClass: {
                                                confirmButton: 'my-confirm-button',
                                            }
                                        });
                                    } else {
                                        navigate("/ListUpload");
                                    }
                                }}
                            >
                                <div className='uploadInput'>
                                    <div className="file-name">
                                        <>
                                            <FiUpload />
                                            Upload
                                        </>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                    {currentRows.length === 0 ?
                        <>
                            <div className='uploadDiv my-5'>
                                <img src="images/Group (5).png" alt="upload-list" />
                                <h3>No List Uploaded</h3>
                                <p>Upload a List to Start The Process</p>
                            </div>
                        </> :

                        <Row className='my-5 tableShowList'>
                            <Col md={12} sm={12}>
                                <div className='table-responsive pl-2'>
                                    <Table hover >
                                        <thead>
                                            <tr>
                                                {/* <th>UUID</th> */}
                                                <th>Name</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>Records</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRows.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td>{row.file_name}</td>
                                                    <td>{

                                                        row.timestamp ? new Date(row.timestamp).toLocaleString('en-US', options) : 'Loading ...'
                                                    }</td>
                                                    <td>{row.status == 1 ? 'Completed' : row.status == -1 ? 'Loading...' : 'Unknown Status'}</td>
                                                    <td>{row.traces}</td>
                                                    <td>

                                                        {row.status == 1 ?
                                                            <button onClick={() => handleDownloadClick(row.id)}>
                                                                Download
                                                            </button>
                                                            : null}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>


                                </div>
                                <Col md={3}>
                                    {renderPagination()}
                                </Col>
                            </Col>
                        </Row>
                    }
                </Container>
            </section>

            <Footer />

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Download Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <button className="buttonDownload m-2" onClick={() => handleFileTypeSelection('primaryUrl')}>
                            Cold Calling Optimized - Primary

                        </button>
                        <button className=" buttonDownload m-2" onClick={() => handleFileTypeSelection('secondaryUrl')}>
                            Cold Calling Optimized - Secondary

                        </button>
                        <button className=" buttonDownload m-2" onClick={() => handleFileTypeSelection('textingOptimizeUrl')}>
                            SMS Marketing Optimized
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ListHistory;
